import React from "react";
import Header from "./header";
import Footer from "./footer";

const Wrapper = (props: any) => {
    return (
        <main className="main-homepage">
            <Header />
            {props.children}
            <Footer />
        </main>
    )
}

export default Wrapper;