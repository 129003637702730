
const Header = () => {
  return (
    <header className="header-area">
      <div className="container">
        <div className="gx-row d-flex align-items-center justify-content-center">
            <img src="/logo.png" height={70} alt="Remax AQUA" />
        </div>
      </div>
    </header>
  );
};

export default Header;
