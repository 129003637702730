
const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="footer-content text-center">
                    <img src="/logo.png" height={100} alt="REMAX AQUA" />
                    <p className="copyright mt-4">
                        &copy; All rights reserved | Created by <span><a href={'https://rapull.com'} target={'_blank'}><img src="/rapull.svg" height={25} alt="Rapull Soft" title="Rapull Soft" /></a></span>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;