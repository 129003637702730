import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import axios from 'axios';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

axios.defaults.baseURL = 'https://lluparapp.remaxaqua.com/api/satisfaction-survey/';
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/satisfaction-survey/';
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-API-Key'] = `YpFJ1/NIBKetqVvKNFsQUhd2c!cit/vB9mSQwnxe9rOtyt6eP1WNJq/xJqanat==MY549!2xUn6?XyC-4OG4RoDQ2glaevkQY893DsiQXkq13my3-s?WIStE3=4ct1P6Vl2j!QgUoNdE93i66Gjx=MXGwu0Cf=uRtJdKHn=Auy/M6URWY4p9AA?S2j7SIndzFM7!IxK?8lhlHi--qpl7GiiM!S?akhtyxRG3FXv=PGl5GouqP6zrKwl4pEtN6XpQxuFzQrLcpPBq7ksvlvEWN6aSCkBIw0dPbOke8UnnEG93UJgH?2fnxddtxD1exL4nzjDQ-7YT04bm/v591Ltn=y!Cth0rQ5mS/=1NVjk4GsxF45SC5443OWK1ZoMaNfv4WG3U8Z/OcPW?15yDFOiYslFeKjJ8CJHA3y4ALSB42D7uzzuatAcfovizUNBH=lEv38qUobwVpNxKNHS5qqL/5Gj2F/tAdCkH8AOTYd5Ij6gwDZ36qtwxZx5HZhsufezK`;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
